<template>
    <v-data-table
        :headers="headers"
        :items="list"
        class="elevation-1"
        :loading="loading"
        loading-text="Загрузка..."
        :server-items-length.sync="pagination.totalItems"
        :items-per-page="pagination.itemsPerPage"
        :options.sync="pagination"
        :footer-props="{
            'items-per-page-text':'Элементов на странице:',
            'items-per-page-all-text':'Все',
            'items-per-page-options':pagination.rowsPerPageItems
        }"
        @pagination="tablePagination"
    >
         <template v-slot:top>
            <v-dialog
                v-model="dialogEdit"
                max-width="500px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                    >
                        Добавить документ
                    </v-btn>
                </template>

                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card color="basil" flat>
                        <v-card-text>
                            <v-text-field
                                label="Название"
                                v-model="editedItem.name"
                                @change="haveEditorChanges = true"
                            ></v-text-field>
                        </v-card-text>
                    </v-card>
                    <v-card color="basil" flat>
                        <v-card-text>
                            <v-select
                                label="Группа"
                                v-model="editedItem.category"
                                :items="categories"
                                :menu-props="{ maxHeight: '400' }"
                                persistent-hint
                                item-text="name"
                                item-value="id"
                                @change="haveEditorChanges = true"
                            ></v-select>
                        </v-card-text>
                    </v-card>
                    <v-card color="basil" flat>
                        <v-card-text>
                            <v-file-input
                                label="Документ"
                                v-model="editedItem.newFile"
                                v-if="editedItem.fileName.length == 0"
                                @change="haveEditorChanges = true"
                            ></v-file-input>
                            <div class="d-flex flex-column justify-space-between align-center" v-if="editedItem.fileName.length > 0">
                                <v-icon
                                    x-large
                                >
                                    mdi-file-document-outline
                                </v-icon>
                                <span>
                                    <a
                                        :href="apiUrl + '/uploads/document/' + editedItem.fileName"
                                    >
                                        {{ editedItem.fileName }}
                                    </a>
                                </span>
                            </div>
                        </v-card-text>
                    </v-card>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closeEdit"
                        >
                            Отмена
                        </v-btn>
                        <v-btn
                            :color="haveEditorChanges?'error darken-1':'blue darken-1'"
                            text
                            @click="save"
                        >
                            Сохранить
                        </v-btn>
                    </v-card-actions>
                </v-card>

                <file-uploader ref="uploader" />
            </v-dialog>

            <v-dialog
            v-model="dialogRemove"
            max-width="500px"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Удаление документа</span>
                    </v-card-title>

                    <v-card-text>Вы действительно хотите удалить выбранный документ?</v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closeRemove"
                        >
                            Отмена
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="removeItem(itemToRemove)"
                        >
                            Удалить
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>

        <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
                title="Изменить документ"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                small
                class="mr-2"
                @click="openRemoveDialog(item)"
                title="Удалить документ"
            >
                mdi-delete
            </v-icon>
        </template>
    </v-data-table>
</template>

<script>
import FileUploader from '@c/FileUploader.vue';
export default {
    name: "Documents",
    components: {
        FileUploader
    },
    data() {
        return {
            apiUrl: process.env.VUE_APP_API,
            list: [],
            categories: [],
            categories2: [],
            headers: [
                {
                    value: 'id',
                    text: 'ID',
                    sortable: false,
                },
                {
                    value: 'name',
                    text: 'Название',
                    sortable: false,
                },
                {
                    value: 'category',
                    text: 'Группа',
                    sortable: false,
                },
                {
                    value: 'fileName',
                    text: 'Файл',
                    sortable: false,
                },
                {
                    value: 'actions',
                    text: 'Действия',
                    sortable: false,
                    align: 'end',
                },
            ],
            loading: false,
            pagination: {
                page: 1,
                itemsPerPage: 10,
                totalItems: -1,
                rowsPerPageItems: [10, 20, 50, 100, -1]
            },
            hasNext: false,
            dialogEdit: false,
            editedIndex: -1,
            editedItem: {
                id: -1,
                name: '',
                fileName: '',
                filePath: '',
                newFile: {},
                category: -1,
            },
            dialogRemove: false,
            itemToRemove: -1,
            haveEditorChanges: false,
        };
    },
    watch: {
        dialogEdit (val) {
            val || this.closeEdit();
        },
        dialogRemove (val) {
            val || this.closeRemove();
        }
    },
    mounted() {
        this.getCategories();
        this.getList(this.pagination.page);
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'Новый документ' : 'Редактирование документа #' + this.editedItem.id;
        },
    },
    methods: {
        tablePagination (data) {
            this.getList(data.page);
        },
        async getCategories () {
            try {
                const { list } = await this.$api("/admin/document_categories");
                this.categories2 = [];
                list.forEach((singleNew, index) => {
                    this.categories.push({'id': list[index].id, 'name': list[index].name});
                    this.categories2[parseInt(list[index].id)] = list[index].name;
                });
            } catch (e) {
                this.$error(e.message);
            }
        },
        async getList (pg = 1) {
            this.loading = true;
            const on = this.pagination.itemsPerPage;
            try {
                const { list, hasNext, totalCount } = await this.$api(`/admin/documents?${new URLSearchParams({
                    pg,
                    on,
                })}`);

                list.forEach((singleNew, index) => {
                    list[index].fileName = '/uploads/document/' + list[index].fileName;
                    list[index].category = this.categories2[parseInt(list[index].category)];
                });

                this.list = list;
                this.hasNext = hasNext;
                this.pagination.page = pg;
                this.pagination.totalItems = totalCount;
            } catch (e) {
                this.$error(e.message);
            }

            this.loading = false;
        },
        async editItem (item) {
            this.editedIndex = await this.list.indexOf(item);
            
            try {
                let r = await this.$api(`/admin/documents/${item.id}`);
                this.editedItem = Object.assign({}, r);
                this.dialogEdit = true;
            } catch (e) {
                this.$error(e.message);
            }
        },
        openRemoveDialog (item) {
            if (parseInt(item.count) > 0) {
                this.$error('Документ используется и не может быть удалён');
                return;
            }
            this.itemToRemove = item;
            this.dialogRemove = true;
        },
        async removeItem (item) {
            try {
                let r = await this.$api(`/admin/documents/${item.id}`, {
                    method: "DELETE"
                });
                if (!r.error) {
                    this.getList(this.pagination.page);
                    this.closeRemove();
                }
            } catch (e) {
                this.$error(e.message);
            }
        },
        closeRemove () {
            this.dialogRemove = false;
            this.$nextTick(() => {
                this.itemToRemove = -1;
            });
        },
        closeEdit () {
            this.dialogEdit = false;
            this.$nextTick(() => {
                this.editedItem = {
                    id: -1,
                    name: '',
                    fileName: '',
                    filePath: '',
                    newFile: {},
                    category: -1,
                };
                this.editedIndex = -1;
            });
        },
        async uploadFile () {
            if ((this.editedItem.newFile != undefined) && (!isNaN(this.editedItem.newFile.size))) {
                const needUpload = [];
                this.editedItem.newFile.id = this.editedItem.id;
                needUpload.push(this.editedItem.newFile);
                const file_name = await this.$refs.uploader.upload(needUpload);
                this.editedItem.filePath = file_name[0].tmp;
                this.editedItem.fileName = this.editedItem.newFile.name;
            }
        },
        async save () {
            await this.uploadFile();
            if (this.editedItem.fileName.length === 0) {
                this.$error('Документ не может быть сохранён без загрузки файла');
                return;
            }

            if (this.editedItem.name.length === 0) {
                this.$error('Документ не может быть сохранён без названия');
                return;
            }

            if (this.editedItem.category === -1) {
                this.$error('Необходимо выбрать группу');
                return;
            }

            if (this.editedIndex > -1) {
                try {
                    let r = await this.$api("/admin/documents/" + this.editedItem.id, {
                        method: "PUT",
                        body: {
                            name: this.editedItem.name,
                            fileName: this.editedItem.fileName,
                            filePath: this.editedItem.filePath,
                            category: this.editedItem.category,
                        },
                    });
                    if (!r.error) {
                        this.getList(this.pagination.page);
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            } else {
                try {
                    let r = await this.$api("/admin/documents", {
                        method: "POST",
                        body: {
                            name: this.editedItem.name,
                            fileName: this.editedItem.fileName,
                            filePath: this.editedItem.filePath,
                            category: this.editedItem.category,
                        },
                    });
                    if (!r.error) {
                        this.getList(this.pagination.page);
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            }
        }
    }
};
</script>
